import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/pages/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {
  }

  success(message: string, title: string = 'Thành công'): void {
    //console.log('Thành công');
    this.toastrService.success(message, title);
  }
  error(message: string, title: string = 'Lỗi'): void {
    //console.log('Lỗi');
    this.toastrService.error(message, title);
  }
  warning(message: string, title: string = 'Cảnh báo'): void {
    //console.log('Cảnh báo');
    this.toastrService.warning(message, title);
  }
  info(message: string, title: string = 'Thông tin'): void {
    //console.log('Thông tin');
    this.toastrService.info(message, title);
  }
  showNotification(message: string, title: string = ''): void {
    this.toastrService.info(message, title, {
      positionClass: 'toast-bottom-right',
      progressBar: true
    });
  }

  showError(message: string, title: string = 'Lỗi'): void {
    this.toastrService.error(message, title, {
      positionClass: 'toast-top-right',
      progressBar: true
    });
  }

  confirm(message: string, title: string = '') {
    //console.log('Xác thực');
    const form: any = {};
    form.title = title;
    form.message = message;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: form,
      panelClass: 'custom-dialog',
      hasBackdrop : false
    });
    return dialogRef.afterClosed();
  }
}
