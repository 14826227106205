// handle user permission and handle when new message has received.
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject, ObservableInput, Observable } from 'rxjs';
import { constant } from '../constants/constant';
import { AlertService } from './alert.service';
@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireAuth: AngularFireAuth,
    private alertService: AlertService,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      ($messaging) => {
        $messaging.onMessage = $messaging.onMessage.bind($messaging);
        $messaging.getToken = $messaging.getToken.bind($messaging);
      }
    );
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(token): void {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {

        // const data = {};
        // data[userId] = token
        // this.angularFireDB.object('fcmTokens/').update(data)
        // TODO push to API getAccesToken with firebase token
        this.setToken(token);
      });
  }
  setToken(token: string): void {
    localStorage.setItem(constant.FIREBASE_TOKEN, token);
  }
  removeToken(): void {
    localStorage.removeItem(constant.FIREBASE_TOKEN);
  }
  getToken(): string {
    return localStorage.getItem(constant.FIREBASE_TOKEN);
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(): void {

    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.updateToken(token);

      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * request permission for notification from firebase cloud messaging
   */
  requestToken(): Observable<string | null> {
    return this.angularFireMessaging.requestToken;
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage(): void {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        this.currentMessage.next(payload);
        if (payload && payload.notification) {
          const notify = payload.notification;
          this.alertService.showNotification(notify.body, notify.title);
        }
      });
  }
}
