import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ToastrModule } from 'ngx-toastr';

import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { RedirectComponent } from './shared/components/redirect/redirect.component';
import { ConfirmDialogComponent } from './pages/confirm-dialog/confirm-dialog.component';
import { MessagingService } from './shared/services/messaging.service';
import { EventEmitterService } from './shared/services/event.emitter.service';
import { ErrorsHandler } from './shared/interceptors/errors-handler';
import { AddHeaderInterceptor } from './shared/interceptors/add-header-interceptor';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { SalesComponent } from './sales/sales/sales.component';
import { AddCustomerDialogComponent } from './sales/add-customer-dialog/add-customer-dialog.component';
import { OrderContentDynamicComponent } from './sales/order-content-dynamic/order-content-dynamic.component';
import { Sale1Component } from './sale1/sale1.component';
import { TabsComponent } from './sale1/tabs/tab.scomponent';
import { TabComponent } from './sale1/tabs/tab.component';
import { DynamicTabsDirective } from './sale1/tabs/dynamic-tabs.directive';
import { PersonEditComponent } from './sale1/people/person-edit.component';
import { PeopleListComponent } from './sale1/people/people-list.component';
import { AddNewHangHoaComponent } from './sales/add-new-hang-hoa/add-new-hang-hoa.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import {TooltipDirective} from './shared/directives/tooltip.directive'

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RedirectComponent,
    ConfirmDialogComponent,
    SalesComponent,
    AddCustomerDialogComponent,
    OrderContentDynamicComponent,
    Sale1Component,
    TabsComponent,
    TabComponent,
    DynamicTabsDirective,
    PersonEditComponent,
    PeopleListComponent,
    AddNewHangHoaComponent,
    TooltipDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    NgxSpinnerModule,
    MatDialogModule,
  ],
  providers: [CookieService, MessagingService, EventEmitterService,
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'vi' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  // entryComponents: [OrderContentDynamicComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
