// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // apiUri: 'https://localhost:44387',
  apiUri:'https://api.duongpham.vn',
  firebase: {
    apiKey: "AIzaSyCYY1_-uU7hXxtEdAvvPv_DnhtIaD4NC7A",
    authDomain: "shop-56310.firebaseapp.com",
    projectId: "shop-56310",

    storageBucket: "shop-56310.appspot.com",
    messagingSenderId: "G-LLNYGE2ZEM",
    appId: "1:281650599648:web:825da1d99506b54006e285",
    measurementId: "G-LLNYGE2ZEM"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
