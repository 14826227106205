import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { AuthenticationClient, TokenPayload } from '../api-connector/sso-connector';
import { constant } from '../constants/constant';
import { AlertService } from './alert.service';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private authenticationClient: AuthenticationClient) { }

  alreadyLogin(): boolean {
    return this.getToken() != null;
  }
  getToken(): string {
    return localStorage.getItem(constant.LOCALSTORAGE_KEY);
  }
  setToken(token: string): void {
    localStorage.setItem(constant.LOCALSTORAGE_KEY, token);
  }
  getFirebaseToken(): string {
    return localStorage.getItem(constant.FIREBASE_TOKEN);
  }
  routingDashboard() {
    this.router.navigate(['/redirect']);
  }
  getAccount(): TokenPayload {
    const token = this.getToken();
    if (token) {
      try {
        const tokenPayLoad: TokenPayload = TokenPayload.fromJS(jwtDecode(token));
        return tokenPayLoad;
      } catch {
        this.alertService.error('Token đăng nhập không hợp lệ. Vui lòng đăng nhập lại');
        this.logoutClient();
      }
    }
  }

  laQuanTriVien(): boolean {
    return this.getAccount().laQuanTriVien;
  }

  getAvatarId() {
    return this.getAccount()?.avatarId;
  }
  logout() {
    this.spinner.show();
    this.authenticationClient.logOut()
      .pipe(finalize(() => {
        this.logoutClient();
        this.spinner.hide();
      }))
      .subscribe();
  }
  private logoutClient(): void {
    localStorage.removeItem(constant.LOCALSTORAGE_KEY);
    this.alertService.success('Tài khoản của bạn đã bị đăng xuất', 'Đăng xuất tài khoản');
    this.routingDashboard();
  }
}
