import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddHangHoaDialogComponent } from 'src/app/module/quan-ly-san-pham/dialog/add-hang-hoa-dialog/add-hang-hoa-dialog.component';
import { DynamicTabsDirective } from 'src/app/sale1/tabs/dynamic-tabs.directive';
import { OrderChanelEnum, OrderCustomerModel, OrderTypeEnum, PaymentTypeEnum, ProductDto, TokenPayload } from 'src/app/shared/api-connector/sso-connector';
import { TabOrderValue } from 'src/app/shared/model/tab-Order';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EventEmitterService } from 'src/app/shared/services/event.emitter.service';
import { AddNewHangHoaComponent } from '../add-new-hang-hoa/add-new-hang-hoa.component';
import { OrderContentDynamicComponent } from '../order-content-dynamic/order-content-dynamic.component';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  productCtrl = new FormControl('');
  activeId: string;
  tabs: TabOrderValue<OrderCustomerModel, OrderContentDynamicComponent>[] = [];
  productSelected: ProductDto;
  reloadProductComponent: any;
  isMenuOpen = false;
  orderCustomerModel = new OrderCustomerModel();
  public selectedId$: BehaviorSubject<boolean>;
  public players$: BehaviorSubject<OrderContentDynamicComponent[]>;
  public player$: Observable<OrderContentDynamicComponent>;

  @ViewChild('tabSet') tabSet: NgbTabset;
  account: TokenPayload;
  @ViewChild('container', {
    read: ViewContainerRef,
    static: true
  }) container: ViewContainerRef;
  scrollStrategy: ScrollStrategy;
  componentRef: ComponentRef<OrderContentDynamicComponent>;
  dynamicContents: OrderContentDynamicComponent[] = [];
  @ViewChild(DynamicTabsDirective) dynamicTabPlaceholder: DynamicTabsDirective;
  constructor(private authService: AuthService,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private readonly scrollStrategyOptions: ScrollStrategyOptions,
    private eventEmitterService: EventEmitterService,
    private renderer: Renderer2) {
    /**
  * This events get called by all clicks on the page
  */
    this.renderer.listen('window', 'click', (e: any) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (!e.target.classList.contains('fa-bars') && !e.target.classList.contains('list-bar') && !e.target.classList.contains('icon-link-custom')) {
        this.isMenuOpen = false;
      }
    });
    this.account = this.authService.getAccount();
    this.setInvoiceDefault(this.orderCustomerModel);
    this.eventEmitterService.getEvent().subscribe((data) => {
      // console.log('Received event data:', data);
      setTimeout(() => {
        this.reloadProductComponent = data;
      }, 100);
  
      // Handle the received data here
    });
  }

  ngOnInit(): void {
    this.scrollStrategy = this.scrollStrategyOptions.noop();
  }

  ngAfterViewInit() {
    this.renderComponent();
    this.activeId = "order_tab" + (this.tabs.length + 1).toString();
    this.tabs.push(new TabOrderValue({
      key: "order_tab" + (this.tabs.length + 1).toString(),
      data: this.orderCustomerModel,
      content: this.dynamicContents[0],
      isRemove: false,
      name: `Hóa đơn 1`
    }));

    setTimeout(() => {
      this.tabSet.select(this.activeId);
    }, 100);
    this.selectTabContent(this.dynamicContents[this.dynamicContents.length - 1]);
  }

  renderComponent() {
    // get a component factory for our TabComponent
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(
      OrderContentDynamicComponent
    );

    // fetch the view container reference from our anchor directive
    const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;

    // alternatively...
    // let viewContainerRef = this.dynamicTabPlaceholder;

    // create a component instance
    const componentRef = viewContainerRef.createComponent(componentFactory);

    // set the according properties on our component instance
    const instance: OrderContentDynamicComponent = componentRef.instance as OrderContentDynamicComponent;

    let orderModel = new OrderCustomerModel();
    this.setInvoiceDefault(orderModel);
    instance.orderCustomerModel = orderModel;
    instance.paymentTypeCtrl.setValue(0);
    this.componentRef = componentRef;
    componentRef.instance.eventEmitter = this.eventEmitterService;
    this.dynamicContents.push(componentRef.instance as OrderContentDynamicComponent);
    this.selectTabContent(this.dynamicContents[this.dynamicContents.length - 1]);
  }

  selectTabContent(tabContent: OrderContentDynamicComponent) {
    //console.log('selectTabContent');
    this.dynamicContents.forEach(tab => (tab.active = false));
    // activate the tab the user has clicked on.
    tabContent.active = true;
  }

  openAddHangHoa() {
    const dialogRef = this.dialog.open(AddNewHangHoaComponent, {
      panelClass: 'custom-dialog',
      minWidth: '66%',
      height: 'auto',
      disableClose: true,
      scrollStrategy: this.scrollStrategy
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.reloadProductComponent = x;
      }
    });
  }

  setInvoiceDefault(orderCustomerModel: OrderCustomerModel) {
    orderCustomerModel.createdId = this.authService.getAccount().id;
    orderCustomerModel.orderChannel = OrderChanelEnum.BAN_TRUC_TIEP; //TODO
    orderCustomerModel.orderType = OrderTypeEnum.BAN_NHANH;
    orderCustomerModel.discount = 0;
    orderCustomerModel.orderTotal = 0;
    orderCustomerModel.price = 0;
    orderCustomerModel.quantity = 0;
    orderCustomerModel.payingAmtInvoice = 0;
    orderCustomerModel.orderTime = new Date();
    orderCustomerModel.orderItemCustomers = [];
    orderCustomerModel.paymentTypeEnum = PaymentTypeEnum.TIEN_MAT;
  }

  selectedTab(item: TabOrderValue<OrderCustomerModel, OrderContentDynamicComponent>): void {
    //console.log(`${item.key}============`, item);
    setTimeout(() => {
      this.tabSet.select(item.key);
      this.selectTabContent(item.content);
    }, 10);
  }

  selectedProduct(item: ProductDto) {
    this.productSelected = item;
    let tabSelected = this.dynamicContents.filter(tab => (tab.active === true));
    tabSelected[0].selectedProduct(item);
    //console.log("tabSelected", tabSelected);
    this.productCtrl.setValue('');
  }

  newPane() {
    this.renderComponent();
    this.tabs.push(new TabOrderValue({
      key: "order_tab" + (this.tabs.length + 1).toString(),
      data: this.orderCustomerModel,
      content: this.dynamicContents[this.dynamicContents.length - 1],
      isRemove: true,
      name: `Hóa đơn ${this.tabs.length + 1}`
    }));

    this.activeId = "order_tab" + (this.tabs.length).toString();

    setTimeout(() => {
      this.tabSet.select(this.activeId);
      this.selectTabContent(this.dynamicContents[this.dynamicContents.length - 1]);
    }, 100);
    // console.log(this.dynamicContents);
  }

  closeTab(item: TabOrderValue<OrderCustomerModel, OrderContentDynamicComponent>) {
    if (item.isRemove == false) {
      return;
    }

    const idx = this.tabs.indexOf(item);
    this.tabs.splice(idx, 1);
    this.activeId = this.tabs[this.tabs.length - 1].key;

    for (let i = 0; i < this.dynamicContents.length; i++) {
      if (this.dynamicContents[i] === item.content) {
        // remove the tab from our array
        this.dynamicContents.splice(i, 1);

        // destroy our dynamically created component again
        let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
        // let viewContainerRef = this.dynamicTabPlaceholder;
        viewContainerRef.remove(i);
        break;
      }
    }
    setTimeout(() => {
      this.tabSet.select(this.activeId);
      this.selectTabContent(this.dynamicContents[this.dynamicContents.length - 1]);
    }, 10);
  }

  openMenuBar() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
