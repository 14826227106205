import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { CommonClient, DiscountTypeEnum, ObjectEnumOfPaymentTypeEnum, OrderChanelEnum, OrderCustomerClient, OrderCustomerModel, OrderCustomerStatusEnum, OrderItemCustomerModel, OrderTypeEnum, PaymentTypeEnum, ProductDto, TokenPayload } from 'src/app/shared/api-connector/sso-connector';
import { TabOrderValue } from 'src/app/shared/model/tab-Order';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AddCustomerDialogComponent } from '../add-customer-dialog/add-customer-dialog.component';
import { PrintInvoicePdf } from '../../shared/model/invoice-print';
import {
  PopupService,
  PopupRef,
  PopupSettings
} from '@progress/kendo-angular-popup';
import { constant } from 'src/app/shared/constants/constant';
import { AlertService } from 'src/app/shared/services/alert.service';
import * as moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { EventEmitterService } from 'src/app/shared/services/event.emitter.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-order-content-dynamic',
  templateUrl: './order-content-dynamic.component.html',
  styleUrls: ['./order-content-dynamic.component.scss']
})

export class OrderContentDynamicComponent implements OnInit {
  @Input() active = false;
  @Input() orderCustomerModel: OrderCustomerModel;
  // @Input() orderCustomerModel: TabOrderValue<OrderCustomerModel>;
  lstProduct: OrderItemCustomerModel[] = [];
  reloadCustomerComponent: any;
  phoneNumber: string = '';
  scrollStrategy: ScrollStrategy;
  date = '';
  accountCtrl = new FormControl('');
  customerCtrl = new FormControl('');
  paymentTypeCtrl = new FormControl(0);
  lstPaymentType: ObjectEnumOfPaymentTypeEnum[] = [];
  activeDiscountTypeVND: boolean = true;
  activeDiscountTypePercent: boolean = false;
  private popupDetailMore: PopupRef;
  private popupRefDiscountCart: PopupRef;
  private popupRefDiscountItemCart: PopupRef;
  isShowChangeAmount: boolean = false;
  isInvoiceByTime: boolean = false;
  // @Input()
  // set productSelected(value: ProductDto) {
  //   this.selectedProduct(value);
  // }
  constructor(private authService: AuthService,
    private dialog: MatDialog,
    private readonly scrollStrategyOptions: ScrollStrategyOptions,
    private elementRef: ElementRef,
    private alertService: AlertService,
    private orderCustomerClient: OrderCustomerClient,
    private spinner: NgxSpinnerService,
    private commomClient: CommonClient,
    private popupService: PopupService,
    private auth: AuthService
  ) {
    // this.setInvoiceDefault();
    this.date = this.toDateString(new Date());
    this.getDataFields();
  }


  ngOnInit(): void {
    this.scrollStrategy = this.scrollStrategyOptions.noop();

  }


  generatePDF(invoice: PrintInvoicePdf) {
    const body = [
      [{ text: 'S.Phẩm', style: 'tableHeader' }, { text: 'Đ.Giá', style: 'tableHeader' }, { text: 'SL', style: 'tableHeader' }, { text: 'T.Tiền', style: 'tableHeader' }],
    ];

    if (this.orderCustomerModel.orderItemCustomers) {
      this.orderCustomerModel.orderItemCustomers.forEach(x => {
        let orderItem = [];
        // xử lý colSpan productName
        orderItem.push({ colSpan: 4, text: `${x.productName} (${x.productUnitName})`, bold: true });
        orderItem.push('');
        orderItem.push('');
        orderItem.push('');
        body.push(orderItem);

        // Xử lý data table còn lại
        orderItem = [];
        orderItem.push('');
        orderItem.push(Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(x.adjustedPrice));
        orderItem.push(x.quantity);
        orderItem.push(Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(x.adjustedPrice * x.quantity));
        body.push(orderItem);
      })
    }
    const bodyInfo = [
      [{ colSpan: 2, text: 'Tổng số lượng' }, '', `${invoice.orderTotalQty}`, ''],
      [{ colSpan: 3, text: 'Tổng tiền hàng' }, '', '', `${Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(invoice.orderTotal)}`],
      [{ colSpan: 3, text: 'Giảm giá' }, '', '', `${Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(invoice.discount)}`],
      [{ colSpan: 3, text: 'Khách cần trả' }, '', '', `${Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(invoice.orderTotal - invoice.discount)}`],
      [{ colSpan: 3, text: 'Khách đưa tiền' }, '', '', `${Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(invoice.payingAmtInvoice)}`],
      [{ colSpan: 3, text: 'Trả tiền thừa' }, '', '', `${Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(invoice.payingAmtInvoice - invoice.orderTotal)}`],
    ];

    const docDefinition = {
      content: [
        {
          alignment: 'center',
          columns: [
            {
              text: 'Phạm Dương Quầy 539',
              fontSize: 14,
              bold: true,
            },
          ]
        },
        {
          alignment: 'center',
          columns: [
            {
              text: 'HÓA ĐƠN BÁN HÀNG',
              fontSize: 12,
              marginTop: 10
            },
          ]
        },
        {
          text: `${invoice.sellerName} : ${invoice.time}`,
          fontSize: 10
        },
        {
          text: `KH : ${invoice.customerName}`,
          fontSize: 10
        },
        {
          text: `SĐT : ${this.phoneNumber}`,
          fontSize: 10
        },
        // table data
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', 20, '*'],
            headerRows: 1,
            body: body
          },
          layout: 'noBorders'
        },

        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', 20, '*'],
            headerRows: 1,
            body: bodyInfo
          },
          layout: 'noBorders'
        },
        { text: `Số hóa đơn : ${invoice.code}`, fontSize: 10 },
        { text: `Thanh toán bằng : ${invoice.paymentType}`, fontSize: 10 },
        {
          text: 'CÁM ƠN QUÝ KHÁCH VÀ HẸN GẶP LẠI',
          style: 'subheader',
        },
      ],
      defaultStyle: {
        columnGap: 10
      },
      pageMargins: [5, 10, 5, 0], // Set all margins to 0 (left, top, right, bottom)
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          margin: [0, 0, 0, 10],
          alignment: 'left'
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 10, 0, 5],
          alignment: 'center'
        },
        tableExample: {
          margin: [0, 5, 2, 5],
          fontSize: 10,
          width: 213,
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: 'black'
        }
      },
      pageSize: {
        width: 213,
        height: 'auto'
      },
    };

    pdfMake.createPdf(docDefinition).print();
  }

  openDiscount(event, template: TemplateRef<any>) {
    if (this.popupRefDiscountCart) {
      this.popupRefDiscountCart.close();
      this.popupRefDiscountCart = null;
    } else {
      this.popupRefDiscountCart = this.popupService.open({
        content: template,
        offset: { top: event.y - 30, left: 1503 },
      });
    }
  }

  openDiscountItem(event, template: TemplateRef<any>) {
    if (this.popupRefDiscountItemCart) {
      this.popupRefDiscountItemCart.close();
      this.popupRefDiscountItemCart = null;
    } else {
      this.popupRefDiscountItemCart = this.popupService.open({
        content: template,
        offset: { top: event.y + 30, left: event.x - 70 },
      });
    }
  }

  openDetailMore(event, template: TemplateRef<any>) {
    if (this.popupDetailMore) {
      this.popupDetailMore.close();
      this.popupDetailMore = null;
    } else {
      this.popupDetailMore = this.popupService.open({
        content: template,
        offset: { top: event.y + 20, left: 1300 },
      });
    }
  }

  public toggle(): void {
    if (this.popupRefDiscountCart) {
      this.popupRefDiscountCart.close();
      this.popupRefDiscountCart = null;
    }

    if (this.popupDetailMore) {
      this.popupDetailMore.close();
      this.popupDetailMore = null;
    }

    if (this.popupRefDiscountItemCart) {
      this.popupRefDiscountItemCart.close();
      this.popupRefDiscountItemCart = null;
    }
  }

  @HostListener("document:keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.toggle();
    }
  }

  switchDiscountType(event, orderCustomerModel: OrderCustomerModel) {
    var target = event.target || event.srcElement || event.currentTarget;
    if (target.id == "discountTypeVnd") {
      this.activeDiscountTypePercent = false;
      this.activeDiscountTypeVND = true;
      orderCustomerModel.discountType = DiscountTypeEnum.VND;
      if (orderCustomerModel.discount > 0) {
        orderCustomerModel.discount = orderCustomerModel.discount * (orderCustomerModel.quantity * orderCustomerModel.price) / 100;
      }
    } else if (target.id == "discountTypePercent") {
      this.activeDiscountTypePercent = true;
      this.activeDiscountTypeVND = false;
      orderCustomerModel.discountType = DiscountTypeEnum.PERCENT;
      if (orderCustomerModel.discount > 0) {
        orderCustomerModel.discount = ((orderCustomerModel.discount / (orderCustomerModel.quantity * orderCustomerModel.price)) * 100)
      }
    }
  }


  activeDiscountItemTypeVND: boolean = true;
  activeDiscountItemTypePercent: boolean = false;
  switchDiscountItemType(event, item: OrderItemCustomerModel) {
    var target = event.target || event.srcElement || event.currentTarget;
    if (target.id == "discountItemTypeVnd") {
      this.activeDiscountItemTypePercent = false;
      this.activeDiscountItemTypeVND = true;
      item.discountType = DiscountTypeEnum.VND;
      if (item.discount > 0) {
        item.discount = item.discount * (item.quantity * item.price) / 100;
      }
    } else if (target.id == "discountItemTypePercent") {
      this.activeDiscountItemTypePercent = true;
      this.activeDiscountItemTypeVND = false;
      item.discountType = DiscountTypeEnum.PERCENT;
      if (item.discount > 0) {
        item.discount = ((item.discount / (item.quantity * item.price)) * 100)
      }
    }
  }

  updateListPrice(item: ProductDto, changeQuantity: number = 1, resetQuantity: boolean = false) {
    let productsOrder = this.orderCustomerModel.orderItemCustomers.filter(x => x.productId == item.id);
    // let qtyOrderByProductId = productsOrder.reduce((sum, item) => sum + Number(item.quantity), 0);

    // sub
    if (changeQuantity > 0) {
      if (item && item.lstPrice.length > 0) {
        if (resetQuantity) {
          for (let index = 0; index < item.lstPrice.length; index++) {
            const price = item.lstPrice[index];
            price.quantity = price.quantityOrigin;
          }
        }

        let index = 0;
        while (changeQuantity > 0 && index < item.lstPrice.length) {
          if (item.lstPrice[index].quantity - changeQuantity >= 0) {
            item.lstPrice[index].quantity -= changeQuantity;
            break;
          } else {
            changeQuantity -= item.lstPrice[index].quantity;
            item.lstPrice[index].quantity = 0;
            index++;
          }
        }
      }
    }
    if (changeQuantity < 0) {
      changeQuantity = -changeQuantity;
      let index = item.lstPrice.length - 1;
      while (changeQuantity > 0 && index !== -1) {
        if (item.lstPrice[index].quantity + changeQuantity <= item.lstPrice[index].quantityOrigin) {
          item.lstPrice[index].quantity += changeQuantity;
          break;
        } else {
          item.lstPrice[index].quantity = item.lstPrice[index].quantityOrigin;
          changeQuantity -= (item.lstPrice[index].quantityOrigin - item.lstPrice[index].quantity);
          index--;
        }
      }
    }

    productsOrder.forEach(x => {
      x.productDto.lstPrice = item.lstPrice;
    })
  }

  // changeDiscountItem(event, item: OrderItemCustomerModel) {
  //   if (item.quantity <= 0) {
  //     return;
  //   }

  //   if (item.discount > 0) {
  //     item.adjustedPrice = item.unitPrice - item.discount;
  //     item.price = item.adjustedPrice * item.quantity;
  //     // item.price = item.price - item.discount;
  //   }

  //   this.bindingOrderCart();
  // }

  changeAdjustedPrice(event, item: OrderItemCustomerModel) {
    // item.discount = item.unitPrice - item.adjustedPrice;
    if (item.quantity < 0) {
      item.quantity = 0;
    }
    item.price = item.adjustedPrice * item.quantity;
    this.bindingOrderCart();

    this.checkIsOverInventory(item.productDto);
  }

  changeUnitPrice(event, item: OrderItemCustomerModel) {
    // item.adjustedPrice = item.unitPrice- item.discount; 
    item.price = item.adjustedPrice * item.quantity;
    this.bindingOrderCart();
  }

  focusout(item: OrderItemCustomerModel) {
    if (!item.quantity || item.quantity == 0) {
      item.quantity = 1;
      item.price = item.adjustedPrice * item.quantity;
      this.bindingOrderCart();
    }

    let qtyOrderByProductId = this.orderCustomerModel.orderItemCustomers.filter(x => x.productId == item.productId).reduce((sum, item) => sum + Number(item.quantity), 0);

    this.updateListPrice(item.productDto, qtyOrderByProductId, true);
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  selectedProduct(item: ProductDto) {
    console.log(item)
    if (item) {
      // var itemExits = this.orderCustomerModel.orderItemCustomers.find(x => x.productId == item.id);
      // if (itemExits) {
      //   itemExits.quantity = itemExits.quantity + 1;
      //   itemExits.price = itemExits.quantity * itemExits.adjustedPrice;
      //   const condition = (productDto: OrderItemCustomerModel) => productDto == itemExits;
      //   this.moveElementWithCondition(this.orderCustomerModel.orderItemCustomers, condition, 0);
      // } else {
      //   this.orderCustomerModel.orderItemCustomers.unshift(new OrderItemCustomerModel({
      //     price: item.price,
      //     productId: item.id,
      //     quantity: 1,
      //     unitPrice: item.price,
      //     productCode: item.code,
      //     productName: item.title,
      //     productUnitName: item.productUnitName || "",
      //     isShowNoteItem: false,
      //     discount: 0,
      //     discountType: DiscountTypeEnum.VND,
      //     adjustedPrice: item.price,
      //     productDto: item,
      //     priceCost: item.priceCost,
      //     inventory: item.inventory
      //   }));
      // }
      this.orderCustomerModel.orderItemCustomers.unshift(new OrderItemCustomerModel({
        price: item.price,
        productId: item.id,
        quantity: 1,
        unitPrice: item.price,
        productCode: item.code,
        productName: item.title,
        productUnitName: item.productUnitName || "",
        isShowNoteItem: false,
        discount: 0,
        discountType: DiscountTypeEnum.VND,
        adjustedPrice: item.price,
        productDto: item,
        priceCost: item.priceCost,
        inventory: item.inventory
      }));
      let qtyOrderByProductId = this.orderCustomerModel.orderItemCustomers.filter(x => x.productId == item.id).reduce((sum, item) => sum + Number(item.quantity), 0);
      this.orderCustomerModel.quantity = this.orderCustomerModel.orderItemCustomers.reduce((sum, item) => sum + Number(item.quantity), 0)
      this.orderCustomerModel.price = this.orderCustomerModel.orderItemCustomers.reduce((sum, item) => sum + Number(item.adjustedPrice) * Number(item.quantity), 0);
      this.orderCustomerModel.orderTotal = this.orderCustomerModel.price - this.orderCustomerModel.discount;
      this.orderCustomerModel.payingAmtInvoice = this.orderCustomerModel.orderTotal;

      this.checkIsOverInventory(item);
      this.updateListPrice(item, qtyOrderByProductId);
    }
  }

  private checkIsOverInventory(item: ProductDto) {
    let qtyP = item.lstPrice.reduce((sum, itemP) => sum + Number(itemP.quantityOrigin), 0);
    let qtyO = this.orderCustomerModel.orderItemCustomers.filter(x => x.productId == item.id).reduce((sum, itemO) => sum + Number(itemO.quantity), 0);
    let isOverInventory = qtyP < qtyO;
    this.orderCustomerModel.orderItemCustomers.filter(x => x.productId == item.id).forEach(z => {
      z.productDto.isOverInventory = isOverInventory;
    });
  }

  moveElementWithCondition<T>(array: T[], condition: (item: T) => boolean, newIndex: number) {
    const indexToMove = array.findIndex(condition);

    if (indexToMove !== -1) {
      const [movedElement] = array.splice(indexToMove, 1);
      array.splice(newIndex, 0, movedElement);
    }
  }

  changeDiscount(event) {
    if (this.orderCustomerModel.quantity <= 0) {
      return;
    }

    let orderOrtotal = this.orderCustomerModel.orderItemCustomers.reduce((sum, item) => sum + Number(item.price), 0);
    if (this.orderCustomerModel.discount > orderOrtotal) {
      this.orderCustomerModel.discount = 0;
    }

    this.bindingOrderCart();
  }

  bindingOrderCart() {
    let orderOrtotal = this.orderCustomerModel.orderItemCustomers.reduce((sum, item) => sum + Number(item.price), 0);
    let orderQuantity = this.orderCustomerModel.orderItemCustomers.reduce((sum, item) => sum + Number(item.quantity), 0);
    if (this.orderCustomerModel.discount > orderOrtotal) {
      return;
    }
    this.orderCustomerModel.price = orderOrtotal;
    this.orderCustomerModel.orderTotal = orderOrtotal - this.orderCustomerModel.discount;
    this.orderCustomerModel.quantity = orderQuantity;
    this.orderCustomerModel.payingAmtInvoice = this.orderCustomerModel.orderTotal;
  }

  removeItem(orderItem: OrderItemCustomerModel) {
    const indexTotal = this.orderCustomerModel.orderItemCustomers.indexOf(orderItem, 0);
    if (indexTotal > -1) {
      this.orderCustomerModel.orderItemCustomers.splice(indexTotal, 1);
    }
    let qtyOrderByProductId = this.orderCustomerModel.orderItemCustomers.filter(x => x.productId == orderItem.productId).reduce((sum, item) => sum + Number(item.quantity), 0);

    this.updateListPrice(orderItem.productDto, qtyOrderByProductId, true);
    this.checkIsOverInventory(orderItem.productDto);
    this.bindingOrderCart();
  }

  getDataFields() {
    forkJoin([this.commomClient.getPaymentTypeEnum()])
      .subscribe(x => {
        this.lstPaymentType = x[0];
      });
  }
  addProductIndex = 0;
  quantity = 0;
  updateItemCart(key, orderItem: OrderItemCustomerModel) {
    let changeQuantity;
    if (key == "updateUpQty") {
      orderItem.quantity += 1;
      changeQuantity = 1;
    } else if (key == "updateDownQty" && orderItem.quantity > 0) {
      orderItem.quantity -= 1;
      changeQuantity = orderItem.productDto.isOverInventory ? 0 : -1;
    }

    changeQuantity !== 0 && this.updateListPrice(orderItem.productDto, changeQuantity);
    orderItem.price = orderItem.quantity * orderItem.adjustedPrice; //* orderItem.unitPrice
    this.checkIsOverInventory(orderItem.productDto);
    this.bindingOrderCart();
    this.spinner.hide();
  }

  private toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-'
      + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
      + ("0" + (date.getDate())).slice(-2))
      + 'T' + date.toTimeString().slice(0, 5);
  }

  setInvoiceDefault() {
    this.orderCustomerModel.createdId = this.authService.getAccount().id;
    this.orderCustomerModel.orderChannel = OrderChanelEnum.BAN_TRUC_TIEP; //TODO
    this.orderCustomerModel.orderType = OrderTypeEnum.BAN_NHANH;
    this.orderCustomerModel.discount = 0;
    this.orderCustomerModel.orderTotal = 0;
    this.orderCustomerModel.price = 0;
    this.orderCustomerModel.quantity = 0;
    this.orderCustomerModel.payingAmtInvoice = 0;
    this.orderCustomerModel.orderItemCustomers = [];
    this.orderCustomerModel.paymentTypeEnum = PaymentTypeEnum.TIEN_MAT;
    this.isInvoiceByTime = false;
    this.date = this.toDateString(new Date());
    this.customerCtrl.setValue(null);
  }

  addCustomer() {
    const dialogRef = this.dialog.open(AddCustomerDialogComponent, {
      panelClass: 'custom-dialog',
      minWidth: '90px',
      width: '80%',
      disableClose: true,
      scrollStrategy: this.scrollStrategy
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.reloadCustomerComponent = x;
      }
    });
  }

  // getLstSuggestMoney() {
  //   let lstSuggestMoney = [];
  //   if (this.orderCustomerModel.orderTotal > 0) {
  //     this.lstSuggestMoney.forEach(x => {
  //       if (this.orderCustomerModel.orderTotal != x && this.orderCustomerModel.orderTotal > constant.VND_1000) {
  //         lstSuggestMoney.push(this.orderCustomerModel.orderTotal);
  //       } else if (this.orderCustomerModel.orderTotal < constant.VND_1000) {
  //         lstSuggestMoney = this.lstSuggestMoney;
  //       }
  //     })
  //   }
  // }

  validateOrder(): boolean {
    let valid = true;
    this.toggle();
    let checkIsOverInventory = this.orderCustomerModel.orderItemCustomers.some(x => x.productDto.isOverInventory);

    if (checkIsOverInventory) {
      this.alertService.warning("Có sản phẩm đã vượt quá số lượng tồn kho, mời bạn kiểm tra lại");
      valid = false;
    }

    if (this.orderCustomerModel.orderTotal <= 0 || this.orderCustomerModel.quantity <= 0 || this.orderCustomerModel.orderItemCustomers.length == 0) {
      this.alertService.warning("Hóa đơn thanh toán không hợp lệ");
      valid = false;
    }

    if ((this.orderCustomerModel.payingAmtInvoice - this.orderCustomerModel.orderTotal) < 0) {
      this.alertService.warning("Số tiền thanh toán hóa đơn không hợp lệ");
      valid = false;
    }

    if (this.accountCtrl.value == null) {
      this.alertService.warning("Người lập hóa đơn không hợp lệ");
      valid = false;
    }

    if (!this.date) {
      this.alertService.warning("Ngày lập hóa đơn không hợp lệ");
      valid = false;
    }

    return valid;
  }

  onSaveDraft() {
    let valid = this.validateOrder();

    if (!valid) {
      return;
    }

    this.orderCustomerModel.createdId = this.accountCtrl.value.id;
    this.alertService.confirm('Bạn có chắc chắn muốn lưu tạm hóa đơn này không?', 'Thanh toán hóa đơn')
      .subscribe(x => {
        if (x) {
          this.spinner.show();
          this.orderCustomerClient.create(new OrderCustomerModel({
            createdId: this.orderCustomerModel.createdId,
            customerId: this.customerCtrl.value ? this.customerCtrl.value.id : null,
            discount: this.orderCustomerModel.discount,
            isUsingPrescription: this.orderCustomerModel.isUsingPrescription,
            orderChannel: this.orderCustomerModel.orderChannel,
            orderTime: moment.utc(this.date).toDate(),
            orderTotal: this.orderCustomerModel.orderTotal,
            orderType: this.orderCustomerModel.orderType,
            price: this.orderCustomerModel.price,
            payingAmtInvoice: this.orderCustomerModel.payingAmtInvoice,
            quantity: this.orderCustomerModel.quantity,
            note: this.orderCustomerModel.note,
            paymentTypeEnum: this.paymentTypeCtrl.value,
            discountType: this.activeDiscountTypeVND ? DiscountTypeEnum.VND : DiscountTypeEnum.PERCENT,
            orderItemCustomers: this.orderCustomerModel.orderItemCustomers,
            status: OrderCustomerStatusEnum.DRAFT,
            isInvoiceByTime: this.isInvoiceByTime
          }))
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(z => {
              this.alertService.success('Thanh toán hóa đơn thành công', 'Thanh toán hóa đơn');
              this.setInvoiceDefault();
            });
        }
      });
  }

  @Input() eventEmitter: EventEmitterService;

  onSave() {
    let valid = this.validateOrder();

    if (!valid) {
      return;
    }

    this.orderCustomerModel.createdId = this.accountCtrl.value.id;
    this.alertService.confirm('Bạn có chắc chắn muốn in hóa đơn này không?', 'Thanh toán hóa đơn')
      .subscribe(x => {
        if (x == true) {
          this.spinner.show();
          this.orderCustomerClient.create(new OrderCustomerModel({
            createdId: this.orderCustomerModel.createdId,
            customerId: this.customerCtrl.value ? this.customerCtrl.value.id : null,
            discount: this.orderCustomerModel.discount,
            isUsingPrescription: this.orderCustomerModel.isUsingPrescription,
            orderChannel: this.orderCustomerModel.orderChannel,
            orderTime: moment.utc(this.date).toDate(),
            orderTotal: this.orderCustomerModel.orderTotal,
            orderType: this.orderCustomerModel.orderType,
            price: this.orderCustomerModel.price,
            payingAmtInvoice: this.orderCustomerModel.payingAmtInvoice,
            quantity: this.orderCustomerModel.quantity,
            note: this.orderCustomerModel.note,
            paymentTypeEnum: this.paymentTypeCtrl.value,
            discountType: this.activeDiscountTypeVND ? DiscountTypeEnum.VND : DiscountTypeEnum.PERCENT,
            orderItemCustomers: this.orderCustomerModel.orderItemCustomers,
            status: OrderCustomerStatusEnum.DONE,
            isInvoiceByTime: this.isInvoiceByTime
          }))
            .pipe(finalize(() => {
              this.spinner.hide()
            }))
            .subscribe(z => {
              this.alertService.success('Thanh toán hóa đơn thành công', 'Thanh toán hóa đơn');
              var modelPrint = new PrintInvoicePdf();
              modelPrint.phone = "0961077031";
              modelPrint.customerName = this.customerCtrl.value ? this.customerCtrl.value.name : "Khách vãng lai";
              modelPrint.discount = this.orderCustomerModel.discount;
              modelPrint.orderTotalQty = this.orderCustomerModel.quantity;
              modelPrint.orderTotal = this.orderCustomerModel.orderTotal;
              modelPrint.time = moment.utc(this.date).format("DD/MM/YYYY hh:mm");
              modelPrint.payingAmtInvoice = this.orderCustomerModel.payingAmtInvoice;
              modelPrint.code = z;
              modelPrint.sellerName = this.accountCtrl.value ? this.accountCtrl.value.fullName : '';

              if (this.paymentTypeCtrl.value == 0) {
                modelPrint.paymentType = "Tiền mặt"
              } else if (this.paymentTypeCtrl.value == 1) {
                modelPrint.paymentType = "Thẻ"
              } else if (this.paymentTypeCtrl.value == 2) {
                modelPrint.paymentType = "Chuyển khoản"
              } else {
                modelPrint.paymentType = "Chưa thanh toán"
              }

              this.eventEmitter.emitEvent(z);
              this.generatePDF(modelPrint);
              this.setInvoiceDefault();
            });
        } else if (x == false) {
          this.spinner.show();
          this.orderCustomerClient.create(new OrderCustomerModel({
            createdId: this.orderCustomerModel.createdId,
            customerId: this.customerCtrl.value ? this.customerCtrl.value.id : null,
            discount: this.orderCustomerModel.discount,
            isUsingPrescription: this.orderCustomerModel.isUsingPrescription,
            orderChannel: this.orderCustomerModel.orderChannel,
            orderTime: moment.utc(this.date).toDate(),
            orderTotal: this.orderCustomerModel.orderTotal,
            orderType: this.orderCustomerModel.orderType,
            price: this.orderCustomerModel.price,
            payingAmtInvoice: this.orderCustomerModel.payingAmtInvoice,
            quantity: this.orderCustomerModel.quantity,
            note: this.orderCustomerModel.note,
            paymentTypeEnum: this.paymentTypeCtrl.value,
            discountType: this.activeDiscountTypeVND ? DiscountTypeEnum.VND : DiscountTypeEnum.PERCENT,
            orderItemCustomers: this.orderCustomerModel.orderItemCustomers,
            status: OrderCustomerStatusEnum.DONE,
            isInvoiceByTime: this.isInvoiceByTime
          }))
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(z => {
              this.alertService.success('Thanh toán hóa đơn thành công', 'Thanh toán hóa đơn');
              this.setInvoiceDefault();
              this.eventEmitter.emitEvent(z);
            });
        }
      });
  }
}
