
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { CmsMenu, OrderCustomerClient, ThongBaoClient, ThongBaoDto, TypeNotificationEnum } from '../../api-connector/sso-connector';
import { AuthService } from '../../services/auth.service';
import { CustomizerService } from '../../services/customizer.service';
import { MessagingService } from '../../services/messaging.service';
import { UploadService } from '../../services/upload.service';
var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public menuItems: CmsMenu[];
  public items: CmsMenu[];
  public searchResult = false;
  public searchResultEmpty = false;
  public openNav = false;
  // tslint:disable-next-line: variable-name
  public right_sidebar = false;
  public text: string;
  public elem;
  public isOpenMobile = false;
  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  avatarId: string;
  urlLogo = '../../../../assets/images/logo/compact-logo.png';
  total = 0;
  itemsNotification: ThongBaoDto[] = [];
  scrollDistance = 1;
  scrollUpDistance = 2;
  throttle = 100;
  isLastPage: boolean;
  constructor(
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    private authService: AuthService,
    private uploadService: UploadService,
    private translate: TranslateService,
    public customize: CustomizerService,
    private messagingService: MessagingService,
    private thongBaoClient: ThongBaoClient,
    private orderCustomerClient : OrderCustomerClient,
    private auth: AuthService
  ) {
    const layoutVersion = customize.getLayoutVersion();
    if (layoutVersion === 'dark-only') {
      this.urlLogo = '../../../../assets/images/logo/compact-logo.png';
    }
    translate.setDefaultLang('en');
    messagingService.currentMessage.subscribe(x => {
      if (x) {
        this.getTotal();
        this.itemsNotification = [];
        this.isLastPage = false;
        this.getListThongBao();
      }
    });
    this.getTotal();
    this.getListThongBao();
  }
  getListThongBao() {
    this.thongBaoClient.getListThongBao(this.itemsNotification.length)
      .subscribe(x => {
        if (x && x.length) {
          this.itemsNotification = this.itemsNotification.concat(x);
        } else {
          this.isLastPage = true;
        }
      });
  }
  getTotal() {
    this.thongBaoClient.getTotal()
      .subscribe(x => {
        this.total = x;
      });
  }

  clearLog(){
    this.orderCustomerClient.clearLog().subscribe();
  }

  markAsRead(item: ThongBaoDto) {
    if (!item.daDoc) {
      item.daDoc = true;
      this.thongBaoClient.markAsRead(item.id)
        .subscribe(() => this.getTotal());
    }

  }
  removeNotification(item: ThongBaoDto) {
    const idx = this.itemsNotification.indexOf(item);
    if (idx !== -1) {
      this.itemsNotification.splice(idx, 1);
      this.thongBaoClient.deleteList([item.id]).subscribe(() => {
        this.getListThongBao();
        this.getTotal();
      });
    }
  }
  getNameTotal() {
    if (this.total > 99) {
      return '99+';
    } else {
      return this.total.toString();
    }
  }
  ngOnDestroy() {
    this.removeFix();
  }
  onLogout() {
    this.authService.logout();
  }
  getLinkAvatar() {
    return this.uploadService.getUrlFile(this.avatarId);
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    this.translate.use(lang);
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) {
      return this.menuItems = [];
    }
    const items = [];
    term = term.toLowerCase();
    this.items.filter(menuItems => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) {
        return false;
      }
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (!subItems.children) {
          return false;
        }
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });
      this.checkSearchResultEmpty(items);
      this.menuItems = items;
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length) {
      this.searchResultEmpty = true;
    }
    else {
      this.searchResultEmpty = false;
    }
  }

  addFix() {
    this.searchResult = true;
    body.classList.add('offcanvas');
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove('offcanvas');
    this.text = '';
  }
  ngOnInit() {
    if (this.auth.getAccount().laQuanTriVien == undefined || this.auth.getAccount().laQuanTriVien == null) {
      this.auth.logout();
    }
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems;
    });
    this.avatarId = this.authService.getAvatarId();
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
  onScrollDown() {
    if (!this.isLastPage) {
      this.getListThongBao();
    }
  }
  getClass(value: ThongBaoDto) {
    switch (value.type) {
      case TypeNotificationEnum.Error:
        return 'txt-danger';
      case TypeNotificationEnum.Success:
        return 'txt-success';
      default:
        return '';
    }
  }
}
