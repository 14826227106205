import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { finalize } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomizerService } from 'src/app/shared/services/customizer.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { AuthenticationClient, LoginRequest, LoginResponse } from 'src/app/shared/api-connector/sso-connector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public newUser = false;
  public user: firebase.User;
  public loginForm = new FormGroup({
    usernameCtrl: new FormControl('', [Validators.required]),
    passwordCtrl: new FormControl('', [Validators.required])
  });
  // urlLogo = '../../../assets/images/logo/compact-logo';
  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private authenticationClient: AuthenticationClient,
    private spinner: NgxSpinnerService,
    public customize: CustomizerService,
    private navService: NavService,
  ) {
    const layout_version = customize.data.color.layout_version;
    if (layout_version === 'dark-only') {
      // this.urlLogo = '../../../assets/images/logo/compact-logo';
    }
    if (authService.alreadyLogin()) {
      authService.routingDashboard();
    }
  }

  ngOnInit() {
  }

  onLogin() {
    if (this.loginForm.valid) {
      const firebaseToken = this.authService.getFirebaseToken();
      this.spinner.show();
      this.authenticationClient.login(new LoginRequest({
        password: this.loginForm.controls.passwordCtrl.value,
        username: this.loginForm.controls.usernameCtrl.value,
        deviceId: 'cms_app',
        firebaseToken,
        osPlatform: 'web',
        osVersion: 'v1',
      }))
        .pipe(
          finalize(() => {
            this.spinner.hide();
          }
          ))
        .subscribe(
          data => {
            this.handlingLogin(data);
          }
        );
    }
  }
  handlingLogin(data: LoginResponse) {
    if (data.isSuccess) {
      this.authService.setToken(data.token);
      this.authService.routingDashboard();
      this.navService.getMenusForCMS();
    } else {
      this.alertService.error(data.message, 'Đăng nhập hệ thống thất bại');
    }
  }

}
